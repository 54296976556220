<template>
    <div>
      <div class="mb-2">
        <a href="#" @click.prevent="$router.go(-1)" class="">
          <i class="bi bi-arrow-left-short icon-sm "></i> Go back
        </a>
      </div>

      <CCard class="rounded shadow-sm">
        <CCardBody>
          <div class="d-flex justify-content-start">
            <div class="col-3 pl-0">
              <input
                type="search"
                class="form-control"
                placeholder="Try searching the data"
                v-model="searchValue"
              />
            </div>

            <div class="col-2 pl-2">
              <select v-model="selectedComplied" class="form-control">
                <option value="">All Complied Status</option>
                <option :value="1">Yes</option>
                <option :value="0">No</option>
              </select>
            </div>

            <div>
              <button
                class="btn btn-outline-secondary text-dark mr-2"
                @click="refreshData"
                :disabled="refreshLoader"
              >
                <i class="bi bi-arrow-clockwise mr-1"></i> Refresh
              </button>
              <div
                v-if="refreshLoader"
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>

          <table class="table table-hover table-bordered table-sm mt-3">
            <thead>
              <tr>
                <th>#</th>
                <th>Requirements</th>
                <th>PDF File</th>
                <th>EXCEL File</th>
                <th>Updated File</th>
                <th>Complied</th>
                <th>Remarks</th>
                <th>Date Created</th>
                <th>Date Updated</th>
                <th>Updated By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" v-if="!filteredList.length && !tableLoader">
                <td colspan="7">No results found</td>
              </tr>
              <tr class="text-center" v-if="tableLoader">
                <td colspan="7">
                  <div class="spinner-border spinner-border-sm mr-1"></div>
                  Loading
                </td>
              </tr>
              <tr v-for="(requirement, index) in paginatedList" :key="requirement.id">
                <th>{{ index + 1 }}</th>
                <td>{{ requirement.description }}</td>
                <td>
                    <a :href="prefix + requirement.path" target="_blank" rel="noopener noreferrer">
                        View File
                    </a>
                </td>
                <td>
                    <a 
                     v-if="requirement.excel_path"
                     :href="prefix + requirement.excel_path" target="_blank" rel="noopener noreferrer">
                        View File
                    </a>
                    <span v-else>No File</span>
                </td>
                <td>
                    <a 
                     v-if="requirement.updated_path"
                     :href="prefix + requirement.updated_path" target="_blank" rel="noopener noreferrer">
                        View File
                    </a>
                    <span v-else>No File</span><br>
                    <small v-if="requirement.updated_path">{{ requirement.file_updated_at | standardDate }}</small>
                </td>
                <td>{{ requirement.is_complied ? 'Yes' : 'No' }}</td>
                <td>{{ requirement.remarks }}</td>
                <td>
                  <span v-if="requirement.created_at">{{
                    requirement.created_at | standardDate
                  }}</span>
                </td>
                <td>
                  <span v-if="requirement.updated_at != requirement.created_at">{{
                    requirement.updated_at | standardDate
                  }}</span>
                </td>
                <td>{{ requirement.updated_by }}</td>
                <td class="text-center">
                  <button 
                    class="btn btn-sm btn-outline-primary"
                    @click.stop="openUpdateModal(requirement)"
                    title="Update Status & Remarks"
                    :aria-label="`Update requirement ${index + 1}`"
                  >
                    <i class="bi bi-pencil-square"></i>
                  </button>
                  <button 
                    class="btn btn-sm btn-outline-danger"
                    @click.stop="deleteRequirement(requirement)"
                    title="Delete Requirement"
                    :aria-label="`Delete requirement ${index + 1}`"
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Pagination section remains the same -->
        </CCardBody>
      </CCard>

      <!-- Update the modal markup -->
      <div 
        class="modal fade" 
        id="updateModal" 
        role="dialog"
        tabindex="-1"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="updateModalLabel">Update Complied Status & Remarks</h5>
              <button 
                type="button" 
                class="close" 
                data-dismiss="modal"
                aria-label="Close"
                tabindex="0"
              >
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="compliedSelect">Complied</label>
                <select 
                  id="compliedSelect"
                  v-model="updateForm.is_complied" 
                  class="form-control"
                  tabindex="0"
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div class="form-group">
                <label for="remarksTextarea">Remarks</label>
                <textarea 
                  id="remarksTextarea"
                  v-model="updateForm.remarks" 
                  class="form-control" 
                  rows="3"
                  tabindex="0"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button 
                type="button" 
                class="btn btn-secondary" 
                data-dismiss="modal"
                tabindex="0"
              >
                Close
              </button>
              <button 
                type="button" 
                class="btn btn-primary" 
                @click="updateRequirement"
                :disabled="updating"
                tabindex="0"
              >
                {{ updating ? 'Updating...' : 'Save Changes' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>

  
  export default {
    name: 'TosfRequirements',
    components: {

    },
    data() {
      return {
        tableLoader: false,
        tosfLists: [],
        searchValue: '',
        refreshLoader: false,
        prefix: 'https://drive.google.com/file/d/',
        currentPage: 1,
        perPage: 10,
        selectedComplied: '',
        updating: false,
        updateForm: {
          id: '',
          is_complied: '',
          remarks: '',
        },
      }
    },
    computed: {
      filteredList() {
        if (!this.searchValue && this.selectedComplied === '') {
          return this.tosfLists;
        }
        
        return this.tosfLists.filter(requirement => {
          const matchesSearch = !this.searchValue || [
            requirement.description,
            requirement.filename,
            requirement.remarks
          ].some(field => 
            field?.toLowerCase().includes(this.searchValue.toLowerCase().trim())
          );

          const matchesComplied = this.selectedComplied === '' || 
            Number(requirement.is_complied) === this.selectedComplied;

          return matchesSearch && matchesComplied;
        });
      },
      paginatedList() {
        const start = (this.currentPage - 1) * this.perPage;
        const end = start + this.perPage;
        return this.filteredList.slice(start, end);
      },
      totalPages() {
        return Math.ceil(this.filteredList.length / this.perPage);
      }
    },
    watch: {
      searchValue() {
        this.currentPage = 1;
      },
      selectedComplied() {
        this.currentPage = 1;
      },
      perPage() {
        this.currentPage = 1;
      }
    },
    methods: {
      previousRoute() {
        this.$router.go(-1);
      },
      refreshData() {
        this.refreshLoader = true;
        this.getTOSFRequirementsList();
      },
      getTOSFRequirementsList() {
        this.tableLoader = true;
        const id = this.$route.params.id;
        const type_increase = this.$route.params.type;
        
        axios
          .get(`api/tosf/application-requirements-list/${id}/${type_increase}`)
          .then((res) => {
            console.log("TOSF Requirements:", res.data);
            this.tosfLists = res.data;
            if (this.refreshLoader) {
              toastr.success('Data refreshed successfully');
            }
          })
          .catch((err) => {
            console.error('Error fetching TOSF list:', err);
            toastr.error('Failed to fetch data');
          })
          .finally(() => {
            this.tableLoader = false;
            this.refreshLoader = false;
          });
      },
      openUpdateModal(requirement) {
        this.updateForm.id = requirement.id;
        this.updateForm.is_complied = requirement.is_complied ? 1 : 0;
        this.updateForm.remarks = requirement.remarks;
        $('#updateModal').modal({
          backdrop: 'static',
          keyboard: false,
          focus: true
        });
      },
      updateRequirement() {
        this.updating = true;
        
        // Convert is_complied to boolean before sending to API
        const updateData = {
          ...this.updateForm,
          is_complied: Boolean(this.updateForm.is_complied)
        };

        axios.put(`api/tosf/update-requirements/${this.updateForm.id}`, updateData)
          .then(response => {
            // Update the local data
            const index = this.tosfLists.findIndex(r => r.id === this.updateForm.id);
            if (index !== -1) {
              this.tosfLists[index].is_complied = Boolean(this.updateForm.is_complied);
              this.tosfLists[index].remarks = this.updateForm.remarks;
            }
            
            $('#updateModal').modal('hide');
            toastr.success('Requirement updated successfully');
          })
          .catch(error => {
            console.error('Error updating requirement:', error);
            toastr.error('Failed to update requirement');
          })
          .finally(() => {
            this.updating = false;
          });
      },
      deleteRequirement(requirement) {
        // Confirm deletion
        if (confirm(`Are you sure you want to delete the requirement: ${requirement.filename} file?`)) {
          axios
            .delete(`api/tosf/delete-requirements/${requirement.id}`)
            .then((response) => {
              console.log('Requirement deleted:', response.data);
              this.getTOSFRequirementsList();
            })
            .catch((error) => {
              console.error('Error deleting requirement:', error);
            });
        } else {
          console.log('Deletion canceled');
        }
      }
    },
    mounted() {
    this.getTOSFRequirementsList();
    },
  };
  </script>
  
<style scoped>
table tr {
  cursor: pointer !important;
}

.bi-arrow-clockwise {
  font-size: 1rem;
}
</style>
  